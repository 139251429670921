@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	padding-bottom: 40px;
	width: 400px;
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
	position: absolute;
	width: 350px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}


.work-item {
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
  }
  
  .work-image {
	width: 50px; /* Adjust based on your design */
	height: 50px; /* Adjust based on your design */
	border-radius: 50%;
	margin-right: 15px;
  }
  
  .work-details {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
  }
  
  .work-company {
	font-weight: bold;
	margin-bottom: 5px;
  }
  
  .work-role {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px; /* Adjust as needed */
  }
  
  .role-title {
	font-size: 0.9em;
	color: #555;
  }
  
  .role-duration {
	font-size: 0.9em;
	color: #777;
	margin-left: 10px; /* Adjust space between job title and date */
	text-align: right; /* Align text to the right */
	flex-grow: 1; /* Allows the date to push to the right */
  }
  
  .works-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
  }
  
  /* Ensure the header icon and text are aligned */
  .works-header svg {
	margin-right: 0.5rem;
  }
  
  /* Additional styles for header, container, etc. */
  